<template>
  <div>
    <TsCardBranchV1
      title="Find Stock Nearby"
      :isStockNearby="true"
      data-testid="nearby-stock-finder"
      input-class="max-w-full"
      append-class="bg-white relative"
      v-bind="$attrs"
      @select="
        (selectedLocality) => emit('selectLocalitySuggestion', selectedLocality)
      "
    >
      <!-- To remove the check button  -->
      <template #view>
        <span></span>
      </template>

      <template #default>
        <div>
          <TsTypography
            v-if="stockStore.branches_with_stock_data.length"
            append-class="text-idle-black text-base mt-5 px-4"
          >
            {{ useTranslation("searchedResults", "Searched Results") }}
            ({{ stockStore.branches_with_stock_data.length }})
          </TsTypography>

          <!-- List of branches with stock -->
          <div
            v-if="stockStore.branches_with_stock_data.length"
            class="max-h-[320px] hide-scroll-bar overflow-y-auto px-4 mt-2"
          >
            <div class="flex flex-col gap-y-3">
              <div
                v-for="branchWithStock in stockStore.branches_with_stock_data"
                :key="branchWithStock.branch.id"
              >
                <TsRadioBranchWithStock
                  data-testid="branch-with-stock-radio"
                  v-model="stockStore.nearby_stock_search_branch_choice"
                  :value="branchWithStock"
                  v-model:quantity="branchWithStock.entered_quantity"
                  :branch-name="branchWithStock.branch.name"
                  :available-stock="branchWithStock.available_stock"
                />
              </div>
            </div>
          </div>

          <div
            v-else-if="loading"
            class="px-4 max-h-[320px] overflow-y-auto hide-scroll-bar"
          >
            <TsBranchTransition v-for="n in 3" :key="n" />
          </div>

          <p v-else class="text-sm text-danger p-4">
            No data available to show for requested stock
          </p>

          <div class="bg-white py-3 shadow-[0px_-8px_12px_0px_#00000014] mt-2">
            <Transition>
              <div
                class="flex justify-between items-center text-primary mb-3 md:mb-2 mx-4 pl-2 border-s-2 border-primary"
              >
                <p class="text-xs max-w-[60ch] text-wrap">
                  Trolley items will be not be affected
                </p>
                <TsIcon name="ic:outline-info" size="20" />
              </div>
            </Transition>

            <div class="max-w-[360px] w-full mx-auto">
              <TsButton
                data-testid=""
                label="Collection"
                icon="si:double-arrow-right-fill"
                block
                :disabled="!stockStore.nearby_stock_search_branch_choice"
                @click="
                  emit(
                    'addForCollection',
                    stockStore.nearby_stock_search_branch_choice!.branch,
                    stockStore.nearby_stock_search_branch_choice!
                      .entered_quantity
                  )
                "
              />
            </div>
          </div>
        </div>
      </template>
    </TsCardBranchV1>
  </div>
</template>

<script lang="ts" setup>
import type { Branch } from "~/types/ecom/branch.type";
import type { WoosmapLocalitySuggestion } from "~/types/woosmap/locality.type";

const stockStore = useStockStore();

type Props = {
  loading?: boolean;
  isStockNearby?: boolean;
};

const props = withDefaults(defineProps<Props>(), { isStockNearby: false });

const emit = defineEmits<{
  addForCollection: [branch: Branch, quantity: number];
  selectLocalitySuggestion: [
    selectedLocality: WoosmapLocalitySuggestion | null
  ];
}>();

defineOptions({
  inheritAttrs: false,
});
</script>
